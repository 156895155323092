import axios from "axios";

const API_URL = process.env.REACT_APP_ECOMMERCE_API_URL; 

export const createProduct = async (productData, storeId) => {
  try {
    const companyId = localStorage.getItem("companyId");
    console.log("companyid:", companyId);
    console.log("storeid:", storeId);
    console.log("Product datos:", productData);

    const response = await axios.post(`${API_URL}/Product`, productData, {
      headers: {
        'Content-Type': 'multipart/form-data', // Especifica el tipo de contenido
        companyId,
        storeId,
      },
    });

    console.log("Product created:", response);
    return response.data;
  } catch (error) {
    console.error("Error creating product:", error);
    throw error;
  }
};

// Update an existing product (PUT /Product/{productId})
export const updateProduct = async (productData, productId, storeId) => {
  try {
    const companyId = localStorage.getItem("companyId");
    const response = await axios.put(`${API_URL}/Product/${productId}`, productData, {
      headers: {
        'Content-Type': 'multipart/form-data',
        companyId,
        storeId,
      },
    });
    console.log("datos despues de update",response)
    console.log("response.data",response.data);

    return response.data;
  } catch (error) {
    console.error(`Error updating product with ID ${productId}:`, error);
    throw error;
  }
};

// Get a single product by ID (GET /Product/{productId})
export const getProduct = async (productId, storeId) => {
  try {
    const companyId = localStorage.getItem("companyId");

    const response = await axios.get(`${API_URL}/Product/${productId}`, {
      headers: {
        companyId,
        storeId,
      },
    });
    return response.data;
  } catch (error) {
    console.error(`Error fetching product with ID ${productId}:`, error);
    throw error;
  }
};

export const deleteProduct = async (productId, storeId) => {
  try {
    const companyId = localStorage.getItem("companyId");
    const response = await axios.delete(`${API_URL}/Product/${productId}`, {
      headers: {
        'Content-Type': 'application/json',
        companyId,
        storeId,
      },
      data: {
        reason: "Producto eliminado por el usuario" // Ajusta el motivo según lo que necesites
      }
    });

    return response.data;
  } catch (error) {
    console.error(`Error deleting product with ID ${productId}:`, error);
    throw error;
  }
};

// Get all products (POST /GetAll)
export const getAllProducts = async (filterData, storeId) => {
  const companyId = localStorage.getItem('companyId');

  try {
    const response = await axios.post(`${API_URL}/GetAll`, 
      {
        filter: {
          createDateTime: {
            startDateTime: filterData.startDateTime, // Pass the start date from filterData
            endDateTime: filterData.endDateTime, // Pass the end date from filterData
          }
        }
      },
      {
        headers: {
          storeId,
        }
      }
    );
    return response.data;
  } catch (error) {
    console.error('Error fetching all products:', error);
    throw error;
  }
};