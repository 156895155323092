import React, { useState, useEffect } from "react";
import styled from "styled-components";
import {
  Title3,
  Form,
  Input,
  Label
} from "../../../styles/styledComponents";

const REGIONES = [
  { key: "rayp", label: "Región de Arica y Parinacota" },
  { key: "rtar", label: "Región de Tarapacá" },
  { key: "rant", label: "Región de Antofagasta" },
  { key: "rata", label: "Región de Atacama" },
  { key: "rcoq", label: "Región de Coquimbo" },
  { key: "rval", label: "Región de Valparaíso" },
  { key: "rmet", label: "Región Metropolitana" },
  { key: "rohi", label: "Región de O'Higgins" },
  { key: "rmau", label: "Región del Maule" },
  { key: "rnub", label: "Región de Ñuble" },
  { key: "rbio", label: "Región del Biobío" },
  { key: "rara", label: "Región de La Araucanía" },
  { key: "rrio", label: "Región de Los Ríos" },
  { key: "rlag", label: "Región de Los Lagos" },
  { key: "rais", label: "Región de Aisén" },
  { key: "rmag", label: "Región de Magallanes" },
];

export function ShopShipping({ initialData, onStoreDataUpdate }) {
  const [selectedPayments, setSelectedPayments] = useState({
    pickup: initialData?.pickUpDetails?.enable ?? true,
    shipping: initialData?.shippingDetails?.enable ?? false,
  });

  const [formData, setFormData] = useState({
    regionalShipping: initializeRegionalShipping(initialData?.shippingDetails?.regionalShipping),
  });

  function initializeRegionalShipping(initialShipping = {}) {
    return REGIONES.reduce((acc, region) => {
      acc[region.key] = initialShipping[region.key] || 0;
      return acc;
    }, {});
  }

  const handleInputChange = ({ target: { name, value } }) => {
    const newValue = isNaN(value) || value === "" ? 0 : Number(value);
    setFormData((prevState) => ({
      ...prevState,
      regionalShipping: {
        ...prevState.regionalShipping,
        [name]: newValue,
      },
    }));
  };

  const handleCheckboxChange = ({ target: { name, checked } }) => {
    setSelectedPayments((prev) => ({ ...prev, [name]: checked }));
  };

  useEffect(() => {
    onStoreDataUpdate({
      shippingDetails: {
        enable: selectedPayments.shipping,
        regionalShipping: formData.regionalShipping,
      },
      pickUpDetails: {
        enable: selectedPayments.pickup,
      },
    });
  }, [selectedPayments, formData, onStoreDataUpdate]);

  return (
    <>
      <Title3>Ingrese métodos de envío</Title3>
      <Form>
        <FormGroup>
          <CheckboxLabel>
            <Checkbox
              type="checkbox"
              name="pickup"
              checked={selectedPayments.pickup}
              onChange={handleCheckboxChange}
            />
            Retiro en tienda
          </CheckboxLabel>
          <CheckboxLabel>
            <Checkbox
              type="checkbox"
              name="shipping"
              checked={selectedPayments.shipping}
              onChange={handleCheckboxChange}
            />
            Entrega a domicilio
          </CheckboxLabel>
        </FormGroup>

        {selectedPayments.shipping && (
          <ShippingForm
            regionalShipping={formData.regionalShipping}
            onInputChange={handleInputChange}
          />
        )}
      </Form>
    </>
  );
}

const ShippingForm = ({ regionalShipping, onInputChange }) => (
  <>
    <h3>Porcentaje de Envío por Región</h3>
    {REGIONES.map(({ key, label }) => (
      <FormGroup key={key}>
        <Label>{label}</Label>
        <Input
          type="number"
          name={key}
          value={regionalShipping[key] || 0}
          onChange={onInputChange}
          min="0"
          max="99"
          style={{ width: "10%" }}
        />
        %
      </FormGroup>
    ))}
  </>
);

const FormGroup = styled.div`
  margin-bottom: 15px;
`;

const Checkbox = styled.input`
  margin-right: 10px;
`;

const CheckboxLabel = styled.label`
  margin-right: 20px;
`;
