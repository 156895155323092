import React, { useState } from "react";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";
import {
  Container,
  CardForm,
  TitleForm,
  InfoForm,
  Title3,
} from "../../../styles/styledComponents";

export function ShopConfigSlider({ storeId, initialData, onStoreDataUpdate }) {
  const [logo, setLogo] = useState({ file: null, url: null }); // Estado para almacenar el archivo y la URL del logo
  const [accordionOpen, setAccordionOpen] = useState(true);
  const [imagen1, setImagen1] = useState(null);
  const [imagen2, setImagen2] = useState(null);
  const [imagen3, setImagen3] = useState(null);
  const [selectedColorGroup, setSelectedColorGroup] = useState(null);
  const colorOptions = [
    { label: "Primera opción", colors: ["#BFC8AD", "#90B494", "#718F94"] },
    { label: "Segunda opción", colors: ["#3BA99C", "#69D1C5", "#7EBCE6"] },
    { label: "Tercera opción", colors: ["#F0A6CA", "#EFC3E6", "#F0E6EF"] },
  ];
  const [colors, setColors] = useState({
    primary: "",
    secondary: "",
    tertiary: "",
  });
  const [sliderFiles, setSliderFiles] = useState([
    { file: null, url: null },
    { file: null, url: null },
    { file: null, url: null },
  ]); // Estado para almacenar las imágenes del slider
  const navigate = useNavigate();

  // Función para gestionar los cambios de cada imagen
  const handleSliderUpload = (e, index) => {
    const file = e.target.files[0];
    if (file) {
      const url = URL.createObjectURL(file);
      const updatedSliderFiles = [...sliderFiles];
      updatedSliderFiles[index] = { file, url };
      setSliderFiles(updatedSliderFiles);
      onStoreDataUpdate({ sliderFiles: updatedSliderFiles.map((slider) => slider.file) });
    }
  };

  const handleLogoUpload = (e) => {
    const file = e.target.files[0];
    if (file) {
      const url = URL.createObjectURL(file);
      setLogo({ file, url });
      onStoreDataUpdate({ logo: file });
    } else {
      // Si no hay archivo, establece logo en null
      setLogo(null);
      onStoreDataUpdate({ logo: null });
    }
  };

  const handleAccordionToggle = () => {
    setAccordionOpen(!accordionOpen);
  };

  const handleColorGroupSelect = (index) => {
    setSelectedColorGroup(index);
    const selectedColors = colorOptions[index].colors;
    const newColors = {
      primary: selectedColors[0],
      secondary: selectedColors[1],
      tertiary: selectedColors[2],
    };
    setColors(newColors);
    onStoreDataUpdate(newColors);
  };

 

  return (
    <>
      <Title3>CONFIGURAR PLANTILLA</Title3>
      <SubTitle>Imagenes y Colores</SubTitle>
      <Section>
        <Label>Seleccione Grupo de Colores</Label>
        <Accordion>
          <AccordionHeader onClick={handleAccordionToggle}>
            {accordionOpen ? "Ocultar Colores" : "Mostrar Colores"}
          </AccordionHeader>
          {accordionOpen && (
            <AccordionContent>
              {colorOptions.map((option, index) => (
                <div key={index}>
                  <OptionLabel>{option.label}</OptionLabel>
                  <ColorGroup>
                    {option.colors.map((color, colorIndex) => (
                      <ColorOption
                        key={colorIndex}
                        style={{
                          backgroundColor: color,
                          border: selectedColorGroup === index ? "3px solid #000" : "none",
                        }}
                        onClick={() => handleColorGroupSelect(index)}
                      />
                    ))}
                  </ColorGroup>
                </div>
              ))}
            </AccordionContent>
          )}
        </Accordion>
      </Section>
      <Section>
        <Label>Logo Tienda</Label>
        <Input type="file" onChange={handleLogoUpload} />
        {logo && <LogoPreview src={logo.url} alt="Logo preview" />}
      </Section>
      <Section>
        <Label>Sliders de la Tienda</Label>
        <p>Sube los sliders que quieres que aparezcan en tu tienda.</p>
        <p style={{backgroundColor: '#add8e6', padding: '10px', borderRadius: '5px'}}>Al actualizar los sliders de la tienda online, todos los banners deberán ser configurados nuevamente. Asegúrate de colocar cada banner en el orden preferido para garantizar que se muestren correctamente en la página.</p>
        Primera slider a mostrar:
        <Input type="file" onChange={(e) => handleSliderUpload(e, 0)} />
      </Section>
      <Section>
        Segundo slider a mostrar:
        <Input type="file" onChange={(e) => handleSliderUpload(e, 1)} />
      </Section>
      <Section>
        Tercer slider a mostrar:
        <Input type="file" onChange={(e) => handleSliderUpload(e, 2)} />
      </Section>
    </>
  );
}

// Estilos con styled-components
// const Container = styled.div`
//   display: flex;
//   flex-direction: column;
//   align-items: center;
//   padding: 20px;
// `;
const buttonStyle = {
  padding: '10px 20px',
  backgroundColor: '#d34343', // Color azul
  color: '#fff',
  border: 'none',
  borderRadius: '5px',
  cursor: 'pointer',
  marginTop: '20px',
};

const Title = styled.h2`
  margin-bottom: 5px;
`;

const SubTitle = styled.h3`
  margin-bottom: 20px;
`;

const Section = styled.div`
  width: 100%;
  margin-bottom: 20px;
`;

const Label = styled.label`
  font-weight: bold;
  margin-bottom: 10px;
  display: block;
`;

const Input = styled.input`
  margin-top: 10px;
  padding: 8px;
  font-size: 14px;
`;

const LogoPreview = styled.img`
  margin-top: 0px;
  width: 200px;
  height: 100px;
  object-fit: contain;
`;

const ContinueButton = styled.button`
  background-color: #000;
  color: #fff;
  border: none;
  padding: 10px 20px;
  cursor: pointer;
  font-size: 16px;

  &:hover {
    background-color: #333;
  }
`;

const Accordion = styled.div`
  width: 100%;
  margin-bottom: 10px;
`;

const AccordionHeader = styled.div`
  background-color: #ccc;
  padding: 10px;
  cursor: pointer;
  font-weight: bold;

  &:hover {
    background-color: #bbb;
  }
`;
const AccordionContent = styled.div`
  padding: 10px;
  background-color: #f9f9f9;
`;

const OptionLabel = styled.div`
  font-weight: bold;
  margin-bottom: 5px;
`;
const ColorGroup = styled.div`
  display: flex;
  gap: 10px;
  margin-bottom: 10px;
`;

const ColorOption = styled.div`
  width: 30px;
  height: 30px;
  cursor: pointer;
  transition: border 0.2s ease;

  &:hover {
    border: 3px solid #000;
  }
`;
export default ShopConfigSlider;
